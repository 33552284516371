import axios from "../http-client/axios";
import TypeRequestedFlow from "../../static-data/TypeRequestedFlow";
import { SCORPIONS_URL } from "../urls/base-urls";
import FormRequestAction from "../../static-data/FormRequestAction";
import requestBuilder from "../request-builder/request-builder";
import { METALICA_URL } from "../urls/base-urls";
import mapperToAttendencePlace from "./attendence-place-mapper";
import ServiceRequestType from "../../static-data/ServiceRequestType";
import { formatCpfCnpj } from "../../utils/format/formtUtils";

// eslint-disable-next-line no-unused-vars
export async function getAttendencePlaces(crm) {
   
  const resp = await axios({
    url: `${SCORPIONS_URL}/doctor-place-service?crm=${crm}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });
  
  return formatDate(resp.data)
}
function formatDate(data) { 
  return data.map(place => { 
    let phones = {};
    let phonesLabel = [];
    place.phones.forEach((element, index) => {
      const phoneFiel = "phone" + (index + 1);
      phonesLabel.push(phoneFiel);
      phones = {
        ...phones,
        [phoneFiel]: element.number,
      };
    });

    const cnpj = place.cnpj.length > 1 ? formatCpfCnpj(place.cnpj.padStart(14, "0")) : place.cnpj;
    return {
      ...place,
      ...phones,
      phonesLabel: phonesLabel,
      cnpj, 
    };
  })
}


export async function addAttendancePlace(formData, attachments) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToAttendencePlace(formData, FormRequestAction.insert),
      TypeRequestedFlow.save,
      attachments,
      ServiceRequestType.DOCTOR_SERVICE_LOCATIONS
    )
  );
  return resp.data;
}

export async function updateAttendencePlace(formData, attachments) {
  var originalData = mapperToAttendencePlace(
    formData.originalData,
    FormRequestAction.update
  );

  var changedData = mapperToAttendencePlace(formData, FormRequestAction.update);

  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      originalData,
      TypeRequestedFlow.update,
      attachments,
      ServiceRequestType.DOCTOR_SERVICE_LOCATIONS,
      null,
      changedData
    )
  );
  return resp.data;
}

export async function deleteAttendencePlace(formData) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToAttendencePlace(formData, FormRequestAction.delete),
      TypeRequestedFlow.delete,
      [],
      ServiceRequestType.DOCTOR_SERVICE_LOCATIONS
    )
  );
  return resp.data;
}

// eslint-disable-next-line no-unused-vars
export async function getAttendencePlacesNotificacoes(crm) {
  const resp = await axios({
    url: `${SCORPIONS_URL}/notification/doctor-place-service?crm=${crm}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return formatDateNotificacoes(resp.data);
}

function formatDateNotificacoes(data) {
  return data.map((place) => {
    
    return {
      clinicName: place.paymentRoute,
      address: {
          addressId: place.cnpj,
          address: criarDescEndereco(place),
          type: place.type,
          phones: place.phones.map(phone => phone.number),
          publishMedicalGuide: place.publishMedicalGuide,
        },
    };
  });
}

function criarDescEndereco(place) {
  let desc = place.publicPlace + ", " + place.number;
  if (place.complement != "") {
    desc = desc + ", " + place.complement + " - ";
  } else {
    desc = desc + ", ";
  }
  desc = desc + place.district + " - " + place.city + "-" + place.state;
  return desc;
}
