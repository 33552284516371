<template>
  <div>
    <b-row class="justify-content-center mb-4">
      <p class="p3">
        Confira seus dados e, caso haja divergências, solicite alteração
        cadastral a qualquer momento.
      </p>
    </b-row>
    <div v-show="!this.loading">
      <AccordionList
        titleKeyName="placeService"
        :items="this.tableItems"
        :itemsFields="this.tableFields"
        :itemsFieldsFunction="this.getItemsFields"
        :getCustomTable="isMobile() ? this.getCustomTable : undefined"
      >
      </AccordionList>

      <AttendencePlaceAddModal
        newRegister="true"
        :attendenceSpecialitiesOptions="this.attendenceSpecialitiesOptions"
        :attendencePaymentRouteOptions="this.attendencePaymentRouteOptions"
      />

      <AttendencePlaceAlterModal
        :loadedFormDataOptions="this.tableItems"
        :attendenceSpecialitiesOptions="this.attendenceSpecialitiesOptions"
        :attendencePaymentRouteOptions="this.attendencePaymentRouteOptions"
      />

      <div class="mt-5" v-if="this.tableItems && this.tableItems.length > 0">
        <b-button
          class="my-3"
          variant="outline-primary"
          v-b-modal:add-attendence-place-modal
        >
          Adicionar local
        </b-button>

        <b-button
          class="m-3"
          variant="primary"
          v-b-modal:alter-attendence-place-modal
        >
          Alterar ou excluir local
        </b-button>
      </div>
    </div>
    <div v-show="this.loading">
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import { getAttendencePlaces } from "../../../../services/attendence-place/attendence-place-service";
import AccordionList from "../../../../components/accordion-list/AccordionList.vue";
import AttendencePlaceAddModal from "../form/AttendencePlaceAddModal.vue";
import AttendencePlaceAlterModal from "../form/AttendencePlaceAlterModal.vue";
import { getMedicalSpecialities } from "../../../../services/medical-specialities/medical-specialities-services";
import { getSocieties } from "../../../../services/payment-options/paymentOptionsService";
import AttendencePlacesDetails from "./AttendencePlacesDetails.vue";
import { isMobile } from "../../../../utils/mobile/mobile";

export default {
  name: "attendence-places-informations",
  setup() {
    return {};
  },
  components: {
    AccordionList,
    AttendencePlaceAddModal,
    AttendencePlaceAlterModal,
  },
  mounted() {
    const crm = this.$store.getters.getUserName;
    this.getAttendencePlaces(crm);
    this.getSpecialitiesOptions(crm);
    this.getPaymentOptions(crm);
  },
  data() {
    return {
      tableFields: [
        {
          key: "cnpj",
          label: "CNPJ",
          tdClass: "text-right",
        },
        {
          key: "cnes",
          label: "CNES",
          tdClass: "text-right",
        },
        {
          key: "cep",
          label: "CEP",
          tdClass: "text-right",
        },
        {
          key: "publicPlace",
          label: "Logradouro",
          tdClass: "text-right",
        },
        {
          key: "number",
          label: "Número",
          tdClass: "text-right",
        },
        {
          key: "complement",
          label: "Complemento",
          tdClass: "text-right",
        },
        {
          key: "district",
          label: "Bairro",
          tdClass: "text-right",
        },
        {
          key: "city",
          label: "Cidade",
          tdClass: "text-right",
        },
        {
          key: "state",
          label: "Estado",
          tdClass: "text-right",
        },
        {
          key: "specialtiesDescription",
          label: "Especialidades de atendimento",
          tdClass: "text-right",
        },
        {
          key: "publishMedicalGuide",
          label: "Divulgar Guia Médico",
          tdClass: "text-right",
        },
      ],
      tableItems: [],
      attendenceSpecialitiesOptions: [],
      attendencePaymentRouteOptions: [],
      loading: true,
    };
  },
  methods: {
    isMobile: isMobile,
    getCustomTable(attendenceSpecialities) {
      return (
        <AttendencePlacesDetails
          attendenceSpecialities={attendenceSpecialities}
        />
      );
    },
    getItemsFields(item) {
      let tableFields = [...this.tableFields];
      if (item.phonesLabel.length == 0) item.phonesLabel.push("phone");
      item.phonesLabel.forEach((element) => {
        tableFields.push({
          key: element,
          label: "Telefone",
          tdClass: "text-right",
        });
        this.arrayMove(tableFields, tableFields.length - 1, 7);
      });
      return tableFields;
    },
    arrayMove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },
    async getAttendencePlaces(getfromLoginCrm) {
      try {
        this.loading = true;
        const attendencePlaces = await getAttendencePlaces(getfromLoginCrm);
        this.tableItems = attendencePlaces;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getAttendencePlaces", error);
      }
    },
    async getSpecialitiesOptions(crm) {
      try {
        this.loading = true;
        const specialities = await getMedicalSpecialities(crm);
        this.loading = false;
        this.attendenceSpecialitiesOptions = specialities;
      } catch (error) {
        this.loading = false;
        console.error("getSpecialitiesOptions", error);
      }
    },
    async getPaymentOptions(crm) {
      try {
        this.loading = true;

        this.attendencePaymentRouteOptions = await getSocieties(crm);

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getPaymentOptions", error);
      }
    },
  },
};
</script>
