<template>
  <div>
    <p class="p4">
      <i class="fas fa-info-circle"></i>
      Importante: Antes de solicitar a inclusão de novo local de atendimento
      certifique-se que o seu nome já está disponível entre os profissionais
      deste local no site no CNES, caso contrário, será necessário anexar a
      ficha CBO
    </p>

    <b-card class="custom-card my-4" v-if="!$props.newRegister">
      <b-form-select
        v-model="selectedOption"
        :options="selectOptions"
        v-on:change="onChangeFormSelect"
        size="lg"
        class=" custom-select-head"
      />
    </b-card>

    <b-card class="custom-card my-4" v-show="showForm()" border-variant="light">
      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          CNPJ
        </div>
        <div class="col">
          <CustomMaskInput
            :class="isMobile() ? 'input-mobile' : ''"
            :initialValue="form.cnpj"
            :options="cnpjMaskOptions"
            :state="validateCnpj('cnpj')"
            placeholder="Digite"
          ></CustomMaskInput>
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          CNES
        </div>
        <div class="col">
          <b-form-input
            v-model.lazy.trim="form.cnes"
            :class="isMobile() ? 'input-mobile' : ''"
            type="number"
            :state="validateField('cnes')"
            placeholder="Digite"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          CEP
        </div>
        <div class="col">
          <CustomMaskInput
            :class="isMobile() ? 'input-mobile' : ''"
            :initialValue="form.cep"
            :options="cepOptions"
            :state="validateFieldCep('cep')"
            placeholder="Digite"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Logradouro
        </div>
        <div class="col">
          <b-form-input
            :class="isMobile() ? 'input-mobile' : ''"
            v-model.lazy.trim="form.publicPlace"
            :state="validateField('publicPlace')"
            placeholder="Digite"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Número
        </div>
        <div class="col">
          <b-form-input
            :class="isMobile() ? 'input-mobile' : ''"
            v-model.lazy.trim="form.number"
            type="number"
            :state="validateField('number')"
            placeholder="Digite"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Complemento
        </div>
        <div class="col">
          <b-form-input
            :class="isMobile() ? 'input-mobile' : ''"
            v-model.lazy.trim="form.complement"
            placeholder="Digite"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Bairro
        </div>
        <div class="col">
          <b-form-input
            :class="isMobile() ? 'input-mobile' : ''"
            v-model.lazy.trim="form.district"
            :state="validateField('district')"
            placeholder="Digite"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Cidade
        </div>
        <div class="col">
          <b-form-input
            :class="isMobile() ? 'input-mobile' : ''"
            v-model.lazy.trim="form.city"
            :state="validateField('city')"
            placeholder="Digite"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Estado
        </div>
        <div class="col">
          <b-form-input
            :class="isMobile() ? 'input-mobile' : ''"
            v-model.lazy.trim="form.state"
            :state="validateField('state')"
            placeholder="Digite"
          />
        </div>
      </div>

      <div
        :class="isMobile() ? 'form-flex-column' : 'form-flex-row'"
        v-for="(phone, index) in form.phonesLabel"
        :key="index"
      >
        <div class="col">
          Telefone
        </div>
        <div class="col">
          <CustomMaskInput
            :class="isMobile() ? 'input-mobile' : ''"
            v-model="form[phone]"
            :initialValue="form[phone]"
            :options="numberOptions"
            :state="validateFieldPhone(phone)"
            placeholder="Digite"
            :field="phone"
          />
          <img
            class="icon-remove"
            src="@/assets/icons/close-circle.svg"
            @click="removePhone(phone)"
          />
          <img
            v-show="form.phonesLabel.length == index + 1"
            class="icon-faq"
            src="@/assets/icons/phone-plus.svg"
            @click="newPhone(index)"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Especialidades de atendimento
        </div>
        <div class="col">
          <custom-v-select
            :class="isMobile() ? 'text-align-left' : ''"
            v-model="form.specialties"
            label="specialtyDescription"
            multiple
            placeholder="Selecione até duas especialidades"
            :options="specialitiesOptions"
            :selectable="validateMaxLength()"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Direcionar pagamento para
        </div>
        <div class="col">
          <custom-v-select
            :class="isMobile() ? 'select-align-left' : ''"
            v-model="form.paymentRoute"
            label="description"
            placeholder="Selecione o meio de pagamento"
            :options="paymentRouteOptions"
            :state="validateField('paymentRoute')"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Divulgar no guia médico
        </div>
        <div class="col">
          <b-form-checkbox
            :class="isMobile() ? 'check-padding text-align-left' : 'ml-auto'"
            switch
            v-model="form.publishMedicalGuide"
            :state="validateCheckBox('publishMedicalGuide')"
          >
            {{ form.publishMedicalGuide ? "Sim" : "Não" }}
          </b-form-checkbox>
        </div>
      </div>

      <b-form-row>
        <b-col>
          <UploadFile
            @updateFile="eventToUpdateFile"
            :multiple="true"
            :description="'Anexar documento para comprovação'"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import UploadFile from "../../../../components/upload-file/UploadFile.vue";
import { inject } from "@vue/composition-api";
import { cnpj } from "cpf-cnpj-validator";
import CustomMaskInput from "../../../../components/forms/custom-mask-input/CustomMaskInput.vue";
import CustomVSelect from "../../../../components/forms/custom-v-select/CustomVSelect.vue";
import { isMobile } from "../../../../utils/mobile/mobile";

const DefaultForm = {
  cnpj: null,
  cnes: null,
  cep: null,
  publicPlace: null,
  number: null,
  complement: null,
  district: null,
  city: null,
  state: null,
  phone1: null,
  specialties: [],
  paymentRoute: null,
  publishMedicalGuide: null,
  files: [],
  phonesLabel: ["phone1"],
  originalData: null,
};

const InvalidEventHandler = () => {
  console.warn("Invalid onChange handler");
};

const NumberOptions = {
  numericOnly: true,
  delimiters: ["(", ") ", " ", "-"],
  blocks: [0, 2, 9],
  onValueChanged: InvalidEventHandler,
};

const CepOptions = {
  numericOnly: true,
  delimiters: ["-"],
  blocks: [5, 3],
  onValueChanged: InvalidEventHandler,
};

const CnpjMaskOptions = {
  numericOnly: true,
  delimiters: [".", ".", "/", "-"],
  blocks: [2, 3, 3, 4, 2],
  onValueChanged: InvalidEventHandler,
};

export default {
  props: [
    "attendenceSpecialitiesOptions",
    "attendencePaymentRouteOptions",
    "loadedFormDataOptions",
    "newRegister",
  ],
  components: {
    CustomMaskInput,
    UploadFile,
    CustomVSelect,
  },
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    return { formData, setForm };
  },
  mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
      selectedEspecialties: null,
      specialitiesOptions: [],
      paymentRouteOptions: [],
      selectedOption: null,
      selectOptions: [],
      numberOptions: {
        ...NumberOptions,
        onValueChanged: this.onChangePhone,
      },
      cepOptions: {
        ...CepOptions,
        onValueChanged: this.onChangeCep,
      },
      cnpjMaskOptions: {
        ...CnpjMaskOptions,
        onValueChanged: this.onChangeCpnj,
      },
    };
  },
  methods: {
    isMobile: isMobile,
    newPhone() {
      const listNumbersLabel = this.form.phonesLabel.map((phone) =>
        parseInt(phone.substring(5))
      );
      const lastNumberLabel = Math.max(...listNumbersLabel);
      const newPhoneField = "phone" + (lastNumberLabel + 1);
      const allValid = this.form.phonesLabel.every((phoneLabel) => {
        this.validateFieldPhone(phoneLabel);
        return this.validations[phoneLabel];
      });
      if (allValid) {
        this.form.phonesLabel.push(newPhoneField);
        this.form[newPhoneField] = null;
      }
    },
    removePhone(phone) {
      let arr = this.form.phonesLabel;
      if (arr.length == 1) {
        this.form[phone] = null;
        return;
      }
      delete this.form[phone];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === phone) {
          arr.splice(i, 1);
          this.form.phonesLabel = arr;
        }
      }
    },
    initForm() {
      if (!this.$props.newRegister) {
        const loadedFormDataOptions = this.$props.loadedFormDataOptions;
        this.selectOptions = this.buildSelectOptions(loadedFormDataOptions);
      }

      this.specialitiesOptions = this.$props.attendenceSpecialitiesOptions;
      this.paymentRouteOptions = this.$props.attendencePaymentRouteOptions;

      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: this.onDelete,
        formSelected: false,
      };
    },
    showForm() {
      return this.selectedOption || this.$props.newRegister;
    },
    validateMaxLength() {
      return () => this.form.specialties?.length < 2;
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    buildSelectOptions() {
      if (this.$props.loadedFormDataOptions.length == 1) {
        this.selectedOption = this.$props.loadedFormDataOptions[0];
        this.onChangeFormSelect(this.selectedOption);
        return this.$props.loadedFormDataOptions.map((formOption) => ({
          value: formOption,
          text: formOption.placeService,
        }));
      }
       return [
        { value: null, text: "Selecione um local", disabled: true },
        ...this.$props.loadedFormDataOptions.map((formOption) => ({
          value: formOption,
          text: formOption.placeService,
        })),
      ];
    },
    onChangeFormSelect(formData) {
      if (!formData) {
        this.form = { ...DefaultForm };
        this.setForm(this.form);
        this.formData = {
          form: { ...this.form },
          onSubmitHandler: this.onSubmit,
          onDeleteHandler: this.onDelete,
          formSelected: false,
        };

        return;
      }

      const { specialties } = formData;
      this.selectedEspecialties = specialties;

      const publishMedicalGuide =
        formData.publishMedicalGuide &&
        formData.publishMedicalGuide.toLowerCase() == "sim";

      const form = {
        ...this.form,
        ...formData,
        publishMedicalGuide: publishMedicalGuide,
      };
      this.form = form;
      this.form.paymentRoute = this.paymentRouteOptions.find((pr) => {
        return pr.description == this.form.paymentRoute;
      });
      this.form.originalData = { ...this.form };
      this.setForm(this.form);

      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: this.onDelete,
        formSelected: true,
      };
    },
    onChangeCpnj(e) {
      this.form.cnpj = e.target.value;
    },
    validateCnpj(name) {
      let value = this.form[name];
      let validation = cnpj.isValid(value);
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    validateCheckBox(name) {
      this.validations[name] = true;
      if (!this.formSubmited) return null;
      return true;
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return !!value;
    },
    onChangePhone(e, field) {
      this.form[field] = e.target.value;
    },
    validateFieldPhone(name) {
      let value = this.form[name];
      let validation = true;
      validation = !!value;
      validation = validation && value.length >= 12;

      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return validation;
    },
    onChangeCep(e) {
      this.form.cep = e.target.value;
    },
    validateFieldCep(name) {
      let value = this.form[name];
      let validation = true;
      validation = !!value;
      validation = validation && value.length == 9;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return validation;
    },
    validateVSelect(field) {
      let value = this.form[field];
      let validation = true;
      validation = !!value;
      if (validation && Array.isArray(value)) validation = value.length > 0;

      this.validations[field] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
    onDelete() {
      return this.selectedOption;
    },
  },
};
</script>

<style lang="scss" scoped>
a.underline {
  color: var(--primary);
  text-decoration: underline;
}
.icon-remove {
  margin-right: 0.8rem;
  margin-left: 0.2rem;
  width: 1.8rem;
  height: 1.8rem;
}
.icon-faq {
  width: 1.8rem;
  height: 1.8rem;
}
.check-padding {
  padding-top: 0.5rem;
}
.form-flex-column {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 0rem;
}
.form-flex-row {
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem 0rem 0rem;
}
.input-mobile {
  padding: 0;
  text-align: left !important;
}

.box-select input {
  text-align: left !important;
}
.alter-form div.card {
  padding: 0rem;
}
</style>
