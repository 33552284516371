<template>
  <div>
    <AlterFormLayout
      modalId="alter-attendence-place-modal"
      formTitle="Alterar local de atendimento"
      deleteButtonText="Solicitar exclusão"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de alteração"
      :onSubmitEvent="onSubmit"
      :onDeleteEvent="onDeleteForm"
      attentionModalId="attendence-place-attention-modal"
      ref="alter-modal"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <AttendencePlaceForm
          :loadedFormDataOptions="$props.loadedFormDataOptions"
          :attendenceSpecialitiesOptions="$props.attendenceSpecialitiesOptions"
          :attendencePaymentRouteOptions="$props.attendencePaymentRouteOptions"
        />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Sua solicitação de alteração do local de atendimento foi enviada."
      buttonText="Ok, entendi!"
      subdescription="Os dados alterados serão analisados e atualizados após a validação."
      :alertPhysicalPerson="this.alertPhysicalPerson"
      :protocol="this.protocol"
    />
    <attention-modal
      modalId="attendence-place-attention-modal"
      title="Atenção!"
      submitButtonText="Sim, solicitar a exclusão"
      cancelButtonText="Não, cancelar a solicitação"
      :onAction="onDeleteAttentionModal"
      ref="attetion-modal"
    >
      <template slot="description">
        <div class="justify-content-center mt-5">
          <div>
            <p class="p1 mb-5">
              Todas as informações deste local de atendimento serão perdidas com
              esta ação.
            </p>
          </div>
          <div>
            <p class="p1 mb-5">
              Deseja mesmo solicitar a exclusão deste local de atendimento?
            </p>
          </div>
        </div>
      </template>
    </attention-modal>
  </div>
</template>

<script>
import AttendencePlaceForm from "./AttendencePlaceForm.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import AttentionModal from "../../../../components/base-modals/AttentionModal.vue";

import {
  deleteAttendencePlace,
  updateAttendencePlace,
} from "../../../../services/attendence-place/attendence-place-service";

export default {
  props: [
    "loadedFormDataOptions",
    "attendenceSpecialitiesOptions",
    "attendencePaymentRouteOptions",
  ],
  components: {
    AlterFormLayout,
    AttendencePlaceForm,
    SuccessModal,
    AttentionModal,
  },
  data() {
    return {
      protocol: "",
      alertPhysicalPerson: null,
    };
  },
  methods: {
    showSuccessModal() {
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
    async onSubmit(formData) {
      console.log("formData.paymentRoute.description -> ", formData.paymentRoute.description)
      this.onAlertPhysicalPerson(formData.paymentRoute.description);
      this.protocol = await updateAttendencePlace(formData, formData.files);
      this.showSuccessModal();
    },
    async onDeleteForm(formData) {
      this.protocol = await deleteAttendencePlace(formData);
      this.showSuccessModal();
    },
    async onDeleteAttentionModal(action) {
      if (action) {
        const alterModal = this.$refs["alter-modal"];
        alterModal.doDelete();
      }
    },
    onAlertPhysicalPerson(value) {
      const showPhysicalPerson = value == "PF - Pessoa Fisíca";
      if (showPhysicalPerson) {
        this.alertPhysicalPerson = "Você optou por direcionar o pagamento para Pessoa Fisíca, lembre-se de emitir suas notas fiscais.";
      } else {
        this.alertPhysicalPerson = null;
      }
    }
  },
};
</script>
