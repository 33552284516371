<script>
export default {
  name: "custom-v-select",
  props: [
    "id",
    "value",
    "label",
    "placeholder",
    "options",
    "multiple",
    "reduce",
    "state",
    "selectable",
    "msgErro",
  ],
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
  render() {
    const getStateClass = () => {
      let state = this.$props.state;
      if (state == null || state == undefined) return state;
      return !state ? "is-invalid" : "is-valid";
    };
    return (
      <div class="box-select">
        <v-select
          selectable={this.$props.selectable}
          id={this.$props.id}
          class={getStateClass()}
          v-model={this.localValue}
          label={this.$props.label}
          placeholder={this.$props.placeholder}
          options={this.$props.options}
          multiple={this.$props.multiple}
          reduce={this.$props.reduce}
        />
        <div
          class="ml-auto invalid"
          v-show={!this.$props.state && this.$props.state != null}
        >
          <i class="fas fa-exclamation-circle invalid mr-1"></i>
          Esse campo é obrigatório.
        </div>
      </div>
    );
  },
};
</script>

<style lang="scss" scoped>
.box-select {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.invalid {
  color: #dc3545;
  text-transform: none;
}
</style>
