/* eslint-disable no-unused-vars */
import TypeRequestedFlow from "../../static-data/TypeRequestedFlow";
import FormRequestAction from "../../static-data/FormRequestAction";
import ServiceRequestType from "../../static-data/ServiceRequestType";
import axios from "../http-client/axios";
import requestBuilder from "../request-builder/request-builder";
import { SCORPIONS_URL, METALICA_URL } from "../urls/base-urls";
import {
  mapperToPaymentRoutingRequest,
  mapperToBankInformationRequest,
} from "./payment-options-mapper";

export async function getBankInformation(crm) {
  const resp = await axios.get(`${SCORPIONS_URL}/doctor-data-bank?crm=${crm}`);
  return resp.data;
  /**
  const mockup = {
    bank: "Banco do Brasil",
    agency: "0999-1",
    account: "22099-9",
    bankCode: "001",
  };
  return mockup;
   */
}

export async function getPaymentsRedirection() {
  const resp = await axios.get(`${SCORPIONS_URL}/v2/payment-route`);
  return resp.data;
}

export async function deletePaymentRouting(formData) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToPaymentRoutingRequest(formData, FormRequestAction.delete),
      TypeRequestedFlow.delete,
      [],
      ServiceRequestType.DOCTOR_PAYMENT_METHODS_TARGETING
    )
  );
  return resp.data;
}

export async function addPaymentRouting(formData, attachments) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToPaymentRoutingRequest(formData, FormRequestAction.insert),
      TypeRequestedFlow.save,
      attachments,
      ServiceRequestType.DOCTOR_PAYMENT_METHODS_TARGETING
    )
  );
  return resp.data;
}

export async function updatePaymentRouting(formData, attachments) {
  var originalData = mapperToPaymentRoutingRequest(
    formData.originalData,
    FormRequestAction.update
  );

  var changedData = mapperToPaymentRoutingRequest(
    formData,
    FormRequestAction.update
  );

  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      originalData,
      TypeRequestedFlow.update,
      attachments,
      ServiceRequestType.DOCTOR_PAYMENT_METHODS_TARGETING,
      null,
      changedData
    )
  );
  return resp.data;
}

export async function updateBankInformation(formData, attachments) {
  var originalData = mapperToBankInformationRequest(
    formData.originalData,
    FormRequestAction.update
  );

  var changedData = mapperToBankInformationRequest(
    formData,
    FormRequestAction.update
  );

  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      originalData,
      TypeRequestedFlow.update,
      attachments,
      ServiceRequestType.DOCTOR_PAYMENT_METHODS_BANK_DATA,
      null,
      changedData
    )
  );
  return resp.data;
}

export async function getBanks() {
  const resp = await axios.get(`${SCORPIONS_URL}/banks`);
  return resp.data;
}

export async function getSocieties() {
  
  const resp = await axios.get(`${SCORPIONS_URL}/societies`);

  return responseSocietiesMapper(resp.data);
}

function responseSocietiesMapper(societies) {

  let requests = [];
  societies.forEach((society) => {
    requests.push({
      cnpj: society.CnpjSociedade,
      description: society.NomeSociedade,
    });
  });

  return requests;
}
