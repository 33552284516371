const specialtiesMapper = (specialties) =>
  specialties?.map((specialty) => specialty.specialtyDescription)?.join(", ");

const phoneMapper = (arr, formData) => arr.map(element => formData[element]).join(", ");

export default function mapperToAttendencePlace(formData, requestAction) {
  return {
    "Ação da solicitação": requestAction,
    CNPJ: formData.cnpj,
    CNES: formData.cnes,
    CEP: formData.cep,
    Logradouro: formData.publicPlace,
    Número: formData.number,
    Complemento: formData.complement,
    Bairro: formData.district,
    Cidade: formData.city,
    Estado: formData.state,
    [formData.phonesLabel.length > 1 ? 'Telefones' : 'Telefone']: phoneMapper(formData.phonesLabel, formData),
    "Especialidades de Atendimento": specialtiesMapper(formData.specialties),
    "Direcionamento de Pagamento": !formData?.paymentRoute?.description ? "" : formData?.paymentRoute?.description,
    "Divulgar Guia Médico": formData.publishMedicalGuide ? "Sim" : "Não",
  };
}
