<template>
  <div class="alter-form">
    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        CNPJ
      </div>
      <div class="col">
        <b :class="isMobile() ? 'text-align-left' : ''">{{
          attendenceSpecialities.cnpj
        }}</b>
      </div>
    </div>
    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        CNES
      </div>
      <div class="col">
        <b>{{ attendenceSpecialities.cnes }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        CEP
      </div>
      <div class="col">
        <b>{{ attendenceSpecialities.cep }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Logradouro
      </div>
      <div class="col">
        <b>{{ attendenceSpecialities.publicPlace }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Número
      </div>
      <div class="col">
        <b>{{ attendenceSpecialities.number }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Complemento
      </div>
      <div class="col">
        <b>{{ attendenceSpecialities.complement }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Bairro
      </div>
      <div class="col">
        <b>{{ attendenceSpecialities.district }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Cidade
      </div>
      <div class="col">
        <b>{{ attendenceSpecialities.city }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Estado
      </div>
      <div class="col">
        <b>{{ attendenceSpecialities.state }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Especialidades de atendimento
      </div>
      <div class="col">
        <b>{{ attendenceSpecialities.specialtiesDescription }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Direcionar pagamento para
      </div>
      <div class="col">
        <b>{{ attendenceSpecialities.paymentRoute }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Divulgar Guia Médico
      </div>
      <div class="col">
        <b>{{ attendenceSpecialities.publishMedicalGuide }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "../../../../utils/mobile/mobile";

export default {
  props: ["attendenceSpecialities"],
  methods: {
    isMobile: isMobile,
  },
};
</script>

<style lang="scss" scoped>
.form-flex-column {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 0rem;
}
.form-flex-row {
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem 0rem 0rem;
}
</style>
