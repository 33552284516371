// eslint-disable-next-line no-unused-vars
export function mapperToPaymentRoutingRequest(formData, requestAction) {
  return {
    "Ação da solicitação": requestAction,
    CNPJ: formData.cnpj,
    "Nome fantasia": formData.name,
  };
}

// eslint-disable-next-line no-unused-vars
export function mapperToBankInformationRequest(formData, requestAction) {
  return {
    "Ação da solicitação": requestAction,
    Banco: formData.bank,
    "Código do Banco": formData.bankCode,
    Agencia: formData.agency,
    Conta: formData.account,
  };
}
