<template>
  <div>
    <AlterFormLayout
      modalId="add-attendence-place-modal"
      formTitle="Adicionar novo local de atendimento"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de novo local de atendimento"
      :onSubmitEvent="onSubmit"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <AttendencePlaceForm
          newRegister="true"
          :attendenceSpecialitiesOptions="$props.attendenceSpecialitiesOptions"
          :attendencePaymentRouteOptions="$props.attendencePaymentRouteOptions"
        />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Sua solicitação de novo local de atendimento foi enviada."
      buttonText="Ok, entendi!"
      subdescription="Os dados inseridos serão analisados e cadastrados após a validação."
      :alertPhysicalPerson="this.alertPhysicalPerson"
      :protocol="this.protocol"
    />
  </div>
</template>

<script>
import AttendencePlaceForm from "./AttendencePlaceForm.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";

import { addAttendancePlace } from "../../../../services/attendence-place/attendence-place-service";

export default {
  props: ["attendenceSpecialitiesOptions", "attendencePaymentRouteOptions"],
  components: {
    AlterFormLayout,
    AttendencePlaceForm,
    SuccessModal,
  },
  data() {
    return {
      protocol: "",
      alertPhysicalPerson: null,
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onSubmit(formData) {
      this.onAlertPhysicalPerson(formData.paymentRoute.description);
      this.protocol = await addAttendancePlace(formData, formData.files);
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    }, 
    onAlertPhysicalPerson(value) {
      const showPhysicalPerson = value == "PF - Pessoa Fisíca";
      if (showPhysicalPerson) {
        this.alertPhysicalPerson = "Você optou por direcionar o pagamento para Pessoa Fisíca, lembre-se de emitir suas notas fiscais.";
      } else {
        this.alertPhysicalPerson = null;
      }
    }
  },
};
</script>
